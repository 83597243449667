import fetch from "node-fetch";

const getMemberClubs = async () => {
  const res = await fetch(`${CMS_URL}/api/teams`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`${res.statusText}: ${res.url}`);
};

const getMemberAssociations = async () => {
  const res = await fetch(`${CMS_URL}/api/associations`);
  if (res.ok) {
    return res.json();
  }
  throw new Error(`${res.statusText}: ${res.url}`);
};

export { getMemberClubs, getMemberAssociations };
