import loadable from "@loadable/component";

import getContactPageSSR from "../ContactPage/server";
import getEducationDetailPageSSR from "../EducationDetailPage/server";
import getGridPageSSR from "../GridPage/server";
import getHomeSSR from "../Home/server";
import getHomeBannerSSR from "../HomeBanner/server";
import getLatestNewsSSR from "../LatestNews/server";
import getMemberAssociationsSSR from "../MemberAssociationPage/server";
import getMemberClubsSSR from "../MemberClubPage/server";
import getNavigationSSR from "../Navigation/server";
import getNewsSSR from "../News/server";
import getNewsArchiveSSR from "../NewsArchive/server";
import getNewsCategoriesSSR from "../NewsCategories/server";
import getNewsSearchSSR from "../NewsSearch/server";
import getPageSSR from "../Page/server";
import getSocialLinksSSR from "../SocialLinks/server";

const DefaultPage = loadable(() => import("../DefaultPage"));
const SingleColumnPage = loadable(() => import("../SingleColumnPage"));
const EducationDetailPage = loadable(() => import("../EducationDetailPage"));
const GridPage = loadable(() => import("../GridPage"));
const ContactPage = loadable(() => import("../ContactPage"));
const Home = loadable(() => import("../Home"));
const News = loadable(() => import("../News"));
const NewsArchivePage = loadable(() => import("../NewsArchivePage"));
const NewsCategoryPage = loadable(() => import("../NewsCategoryPage"));
const NewsSearchPage = loadable(() => import("../NewsSearchPage"));
const EducationPage = loadable(() => import("../EducationPage"));
const MemberClubPage = loadable(() => import("../MemberClubPage"));
const MemberAssociationPage = loadable(() =>
  import("../MemberAssociationPage")
);

const DEFAULT_FETCHDATA = [getNavigationSSR, getSocialLinksSSR];

function Empty() {
  return null;
}

const getRouteConfig = (name) => {
  switch (name) {
    case "home":
      return {
        Component: Home,
        fetchData: [
          ...DEFAULT_FETCHDATA,
          getHomeSSR,
          getLatestNewsSSR,
          getHomeBannerSSR,
        ],
      };
    case "newssearchpage":
      return {
        Component: NewsSearchPage,
        fetchData: [...DEFAULT_FETCHDATA, getNewsSearchSSR],
      };
    case "newsfolder":
      return {
        Component: NewsArchivePage,
        fetchData: [
          ...DEFAULT_FETCHDATA,
          getNewsArchiveSSR,
          getNewsCategoriesSSR,
        ],
      };
    case "newscategory":
      return {
        Component: NewsCategoryPage,
        fetchData: [
          ...DEFAULT_FETCHDATA,
          getNewsArchiveSSR,
          getNewsCategoriesSSR,
        ],
      };
    case "newsitem":
      return {
        Component: News,
        fetchData: [...DEFAULT_FETCHDATA, getNewsSSR],
      };
    case "gridpage":
      return {
        Component: GridPage,
        fetchData: [...DEFAULT_FETCHDATA, getGridPageSSR],
      };
    case "contactpage":
      return {
        Component: ContactPage,
        fetchData: [...DEFAULT_FETCHDATA, getContactPageSSR],
      };
    case "educationfolder":
      return {
        Component: EducationPage,
        fetchData: [...DEFAULT_FETCHDATA, getPageSSR],
      };
    case "educationdetail":
      return {
        Component: EducationDetailPage,
        fetchData: [...DEFAULT_FETCHDATA, getEducationDetailPageSSR],
      };
    case "teamsfolder":
      return {
        Component: MemberClubPage,
        fetchData: [...DEFAULT_FETCHDATA, getPageSSR, getMemberClubsSSR],
      };
    case "memberassociationsfolder":
      return {
        Component: MemberAssociationPage,
        fetchData: [...DEFAULT_FETCHDATA, getPageSSR, getMemberAssociationsSSR],
      };
    case "bannerpagesinglecolumn":
      return {
        Component: SingleColumnPage,
        fetchData: [...DEFAULT_FETCHDATA, getPageSSR],
      };
    case "page":
      return {
        Component: DefaultPage,
        fetchData: [...DEFAULT_FETCHDATA, getPageSSR],
      };
    default:
      return {
        Component: Empty,
      };
  }
};

export default getRouteConfig;
