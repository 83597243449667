import { fetchMemberAssociations } from "../../../service/Members";
import { SET_ITEMS } from "./constants";

const setItems = (data) => ({
  type: SET_ITEMS,
  data,
});

// eslint-disable-next-line import/prefer-default-export
export const getItems = () => (dispatch) =>
  fetchMemberAssociations()
    .then((response) => {
      dispatch(setItems(response));
      return response;
    })
    .catch((error) => {
      throw error;
    });
