import {
  toCroppedBannerImage,
  toCroppedImage,
  toImage,
  toVideo,
} from "../adapter";

export const toMeta = (data) => {
  if (data) {
    return {
      title: data.title,
      description: data.description,
      keywords: data.keywords,
      creator: data.creator,
      date: data.date,
      image: data.thumbnail,
    };
  }
  return null;
};

export const toGallery = (data) => {
  if (data && data.length) {
    return data.map((n) => ({
      id: n.id,
      title: n.title,
      alt: n.alternateText || n.title,
      caption: n.alternateText,
      images: toCroppedImage(n.crops, "square"),
    }));
  }
  return [];
};

export const toCover = (data) => {
  if (data && data.length) {
    return data.slice(0, 1).map((n) => ({
      id: n.id,
      title: n.title,
      alt: n.alternateText || n.title,
      caption: n.alternateText,
      images: toCroppedImage(n.crops, "wide"),
    }))[0];
  }
  return null;
};

export const toBannerImage = (data) => {
  if (data && data.length) {
    return data.slice(0, 1).map((n) => ({
      id: n.id,
      title: n.title,
      alt: n.alternateText || n.title,
      caption: n.alternateText,
      images: toCroppedBannerImage(n.crops),
    }))[0];
  }
  return null;
};

export const toBanners = (data) => {
  if (data && data.length) {
    return data.map((n) => {
      const banner = {
        id: n.id,
        title: n.title,
        summary: n.summary,
        color: n.textColor,
        images: toBannerImage(n.images),
      };

      if (n.link) {
        banner.url = n.link.url;
        banner.isExternalUrl = !n.link.isInternal;
      }

      if (n.videoWebM) {
        banner.videoWebM = toVideo(n.videoWebM);
        banner.useVideo = true;
      }
      if (n.videoMp4) {
        banner.videoMp4 = toVideo(n.videoMp4);
        banner.useVideo = true;
      }

      return banner;
    });
  }
  return null;
};

const toLinks = (data) => {
  if (data && data.length) {
    return data.map((n) => ({
      name: n.name,
      url: n.link,
      icon: n.icon,
      fileSize: n.fileSize,
    }));
  }
  return [];
};

export const toDownloads = (data) => {
  if (data && data.length) {
    return data.map((n) => ({
      id: n.id,
      title: n.title,
      links: toLinks(n.downloads),
    }));
  }
  return [];
};

export const toLink = (data) =>
  data
    ? {
        url: data.url,
        isNewWindow: data.isNewWindow,
        isExternal: data.isExternal,
      }
    : null;

const toContactForm = (data) => {
  if (data && data.id) {
    return {
      id: data.id,
      type: data.contactFormTypeSlug,
      title: data.title,
      summary: data.subtitle,
      fields: data.fields?.map((n) => ({
        label: n.label,
        name: n.name,
        type: n.type,
        values: n.values?.map((m) => m.split(":")[0]),
        isRequired: n.isRequired,
      })),
    };
  }
  return null;
};

export const toPage = (data) => {
  if (data) {
    return {
      id: data.id,
      name: data.name,
      title: data.title,
      titleMT: data.titleMT,
      summary: data.summary,
      html: data.html,
      htmlMT: data.htmlMT,
      url: data.url,
      subNav: data.subNavItemName,
      thumbnail: toImage(data.thumbnail),
      banners: toBanners(data.banners),
      cover: toCover(data.gallery),
      downloads: toDownloads(data.downloadCategories),
      bannerImage: toBannerImage(data.gallery),
      gallery: toGallery(data.gallery),
      meta: toMeta({
        ...data.meta,
        thumbnail: data.thumbnail ? data.thumbnail.imageUrl : "",
      }),
      contactForm: toContactForm(data.contactForm),
      showBreadcrumbs: data.isBreadcrumbsShowed,
    };
  }
  return null;
};

export const toGridPage = (data) => {
  if (data) {
    return {
      ...toPage(data),
      gridColumnsDesktop: data.gridSection?.columnsCount,
      gridColumns: data.gridSection?.mobileColumnsCount,
      items: data.gridSection?.gridItems?.map((item) => ({
        id: item.id,
        title: item.title,
        subtitle: item.subtitle,
        image: toImage(item.image),
        link: toLink(item.link),
      })),
      footerHtml: data.gridSection?.footerHtml,
      isMasonry: data.gridSection?.masonry,
    };
  }
  return null;
};

export const toContactPage = (data) => {
  if (data) {
    return {
      ...toPage(data),
      mapUrl: data.googleMapUrl,
      items: data.contactItems?.map((item) => ({
        id: item.id,
        title: item.title,
        subtitle: `<p>${item.text.replace(/\n/g, "<br />")}</p>`,
        image: toImage(item.icon),
      })),
    };
  }
  return null;
};
