import reducerRegistry from "../../reducerRegistry";
import { getItems } from "./actions";
import { REDUCER_NAME } from "./constants";
import reducer from "./reducer";

const getMemberAssociationsSSR = (store) => {
  reducerRegistry.register(REDUCER_NAME, reducer);
  return store.dispatch(getItems());
};

export default getMemberAssociationsSSR;
