import { toMemberAssociations, toMemberClubs } from "./adapter";
import { getMemberAssociations, getMemberClubs } from "./dataService";

const fetchMemberClubs = (data) =>
  getMemberClubs(data)
    .then((response) => toMemberClubs(response))
    .catch((error) => {
      throw error;
    });

const fetchMemberAssociations = (data) =>
  getMemberAssociations(data)
    .then((response) => toMemberAssociations(response))
    .catch((error) => {
      throw error;
    });

export { fetchMemberClubs, fetchMemberAssociations };
