import { fetchContactPage } from "../../../service/Page";
import { isError, isLoading, setMeta, setUrl } from "../App/actions";
import { RESET_PAGE, SET_PAGE } from "../Page/constants";

const setPage = (data, key) => ({
  type: SET_PAGE,
  data,
  key,
});

export const resetPage = (key) => ({
  type: RESET_PAGE,
  key,
});

const getPageFactory =
  (promise) =>
  ({ path }) =>
  (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(false));

    return promise
      .then((response) => {
        dispatch(setUrl(path));
        dispatch(setPage(response, path));
        dispatch(setMeta(response && response.meta));
        dispatch(isLoading(false));
        return response;
      })
      .catch((error) => {
        dispatch(isLoading(false));
        dispatch(isError(true));
        console.error(error);
        throw error;
      });
  };

export const makeGetPage = (func) => (args) => (dispatch) =>
  getPageFactory(func(args)(dispatch))(args)(dispatch);

export const getPage =
  ({ path }) =>
  (dispatch) =>
    fetchContactPage({ path })
      .then((response) => {
        dispatch(setPage(response, path));
        return response;
      })
      .catch((error) => {
        throw error;
      });
