import { toSocialLinks } from "../Social/adapter";

export const toMemberClub = (data) => {
  if (data) {
    try {
      return {
        id: data.id,
        title: data.title,
        url: `${MC_URL}/team/${data.teamId}`,
        socialLinks: toSocialLinks(data.socialLinks),
        imageUrl: data.logoUrl,
        websiteUrl: data.websiteUrl,
        founded: data.foundation ? data.foundation : null,
        president: data.president?.displayName,
        secretary: data.secretary?.displayName,
      };
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
};

export const toMemberClubs = (data) => {
  if (data) {
    return data.map((item) => toMemberClub(item));
  }
  return [];
};

export const toMemberAssociation = (data) => {
  if (data) {
    try {
      return {
        id: data.id,
        title: data.title,
        socialLinks: toSocialLinks(data.socialLinks),
        imageUrl: data.logoUrl,
        websiteUrl: data.websiteUrl,
        founded: data.foundation ? data.foundation : null,
        president: data.president?.displayName,
        secretary: data.secretary?.displayName,
      };
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
};

export const toMemberAssociations = (data) => {
  if (data) {
    return data.map((item) => toMemberAssociation(item));
  }
  return [];
};
