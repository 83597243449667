import { createSelector } from "reselect";

import { REDUCER_NAME, SET_ERROR, SET_ITEMS } from "./constants";

const initialState = {
  isReady: false,
  isError: false,
  items: [],
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEMS:
      return {
        ...state,
        isReady: true,
        isError: false,
        items: action.data,
      };
    case SET_ERROR:
      return {
        ...state,
        isReady: false,
        isError: true,
      };
    default:
      return state;
  }
};

const getState = (state) => {
  if (state[REDUCER_NAME]) {
    return state[REDUCER_NAME];
  }
  return initialState;
};

export const selectIsError = createSelector(getState, (n) => n.isError);

export const selectIsReady = createSelector(getState, (n) => n.isReady);

export const selectItems = createSelector(getState, (n) => n.items);

export default reducer;
