import { toBreadcrumbs, toNavigation } from "./adapter";
import { getBreadcrumbs, getNavigation } from "./dataService";

const fetchNavigation = (data) =>
  getNavigation(data)
    .then((response) => toNavigation(response))
    .catch((error) => {
      console.error(error);
      throw error;
    });

const fetchBreadcrumbs = (data) =>
  getBreadcrumbs(data)
    .then((response) => toBreadcrumbs(response))
    .catch((error) => {
      console.error(error);
      throw error;
    });

export { fetchNavigation, fetchBreadcrumbs };
