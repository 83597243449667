const toNavigationItems = (data) =>
  data?.length
    ? data.map((n) => ({
        id: n.id,
        title: n.title,
        url: n.url,
        isNewWindow: n.isNewWindow,
        isExternal: n.isExternal,
        icon: n.icon?.imageUrl,
        children: toNavigationItems(n.children),
      }))
    : [];

const toBreadcrumbItems = (data) =>
  data?.length
    ? data.reduce((acc, n) => {
        let itemArray = [
          ...acc,
          {
            id: n.id,
            title: n.title,
            url: n.url,
            isNewWindow: n.isNewWindow,
            isExternal: n.isExternal,
            icon: n.icon?.imageUrl,
          },
        ];
        if (n.children) {
          itemArray = [...itemArray, ...toBreadcrumbItems(n.children)];
        }

        return itemArray;
      }, [])
    : [];

const toNavigation = (data) =>
  data?.children ? toNavigationItems(data.children) : [];

const toBreadcrumbs = (data) => {
  if (!data) {
    return [];
  }
  return toBreadcrumbItems([data]);
};

export { toNavigation, toBreadcrumbs };
